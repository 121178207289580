import { CurrentUser } from '../context';

export function redirectToWizard(user: CurrentUser) {
  const isPerson = user?.currentUser?.type === 'person';
  const isBusiness = user?.currentUser?.type === 'business';
  const hasCategories = user?.currentUser?.categories?.length > 0;
  const registrationStep = Number(user?.currentUser?.registrationStep);

  return (
    (isPerson && registrationStep === 0) ||
    (isPerson && hasCategories && registrationStep !== 7) ||
    (isBusiness && registrationStep < 3) ||
    (isPerson && !hasCategories && registrationStep < 2)
  );
}
