import React from 'react';
import { useRouter } from 'next/router';
import { User } from '@dinbog/models';
import { SpinnerIcon } from '@dinbog/ui';
import { useUser } from '../hooks';
import { useFeed } from '../hooks/api/posts';
import { useUserRecommendations } from '../hooks/api/users';
import { Layout, Feed } from '../components';
import { redirectToWizard } from '../utils/redirectToWizard';

export default function HomePage() {
  const { currentUser: user } = useUser();
  const router = useRouter();

  React.useEffect(() => {
    if (redirectToWizard(user)) {
      router.push('/sign-up/wizard');
    }
  }, [user]);

  const [pagination, setPagination] = React.useState({
    page: 1,
    perPage: 10,
  });

  const { data, isLoading } = useFeed(
    {
      user: (user?.currentUser?.user as User)?._id as string,
      ...pagination,
    },
    {
      enabled: !!user,
    }
  );

  const {
    data: recommendationsTalents,
    isLoading: loadingRecommendationsTalents,
    refetch: refetchTalents,
  } = useUserRecommendations(
    {
      user: user?._id,
      type: 'talent',
      page: 1,
      perPage: 8,
    },
    {
      enabled: !!user,
    }
  );
  const {
    data: recommendationsBusinesses,
    isLoading: loadingRecommendationsBusinesses,
    refetch: refetchBusinesses,
  } = useUserRecommendations(
    {
      user: user?._id,
      page: 1,
      perPage: 8,
      type: 'business',
    },
    {
      enabled: !!user,
    }
  );

  if (!user) {
    return (
      <div className="w-full h-[70vh] flex  opacity-70 z-30">
        <SpinnerIcon className="m-auto w-24 h-24 text-gray-200 animate-spin  fill-primary-500" />
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  return (
    <Layout className="bg-background-200" horizontalPaddingSize="small">
      <Feed
        data={data}
        setPagination={setPagination}
        loading={isLoading}
        pagination={pagination}
        recommendations={{
          talents: recommendationsTalents?.items ?? [],
          businesses: recommendationsBusinesses?.items ?? [],
          refetchTalents,
          refetchBusinesses,
        }}
        loadingRecommendations={
          loadingRecommendationsTalents || loadingRecommendationsBusinesses
        }
      />
    </Layout>
  );
}
HomePage.auth = true;
